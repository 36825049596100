function initMap() {
  const lat = parseFloat(document.getElementById("js-map-coordinates").getAttribute('data-lat'));
  const lng = parseFloat(document.getElementById("js-map-coordinates").getAttribute('data-lng'));
  
  const city = {
    lat: lat,
    lng: lng
  };

  console.log(document.getElementById("googleMap"))
  const map = new google.maps.Map(document.getElementById("googleMap"), {
    zoom: 14,
    center: city
  });

  const marker = new google.maps.Marker({
    position: {
      lat: lat,
      lng: lng
    },
    map: map
  });

  return map;
}
                    
window.initMap = initMap;